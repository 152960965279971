import React from 'react';
import HubspotForm from 'react-hubspot-form';

import './Contact.styles.scss';

function Contact() {
    return (
        <div className="Contact-container">
            <div className="container py-5">
                <div className="row align-items-center justify-content-sm-between">
                    <div className="col-lg-6 white-text col-in-touch-text">
                        <h2 className="Contact-touch-title">Get in touch</h2>
                        <p className="Contact-touch-text in-touch-text pe-lg-5">
                            Drop us a line to inquire about pricing, discuss partnership opportunities or learn more
                            about what we do.
                        </p>
                    </div>
                    <div className="col-lg-6 py-3">
                        <div id="contactForm">
                            <div className="row">
                                <div className="col-lg-12 d-flex mt-3">
                                    <HubspotForm
                                        region="na1"
                                        portalId="5014803"
                                        formId="11a307e9-9717-4c6c-bd58-af320ac734e1"
                                        loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
