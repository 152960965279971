import React from 'react';
import './Faqs.styles.scss';

const Faqs = () => (
    <div className="container mb-5 faq-container">
        <div className="row justify-content-sm-center">
            <div className="col-lg-8 col mt-5">
                <h4 className="faq-title mb-3">FAQ</h4>
                <div className="accordion faq-accordion mb-3" id="accordionExample">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                                <button
                                    className="btn btn-link btn-block text-left accordion-button collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    What is BIN?
                                </button>
                            </h2>
                        </div>

                        <div
                            id="collapseOne"
                            className="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                        >
                            <div className="card-body">
                                <p>
                                    When a patient requests a prescription to be filled at the pharmacy, the pharmacy
                                    collects pertinent information on their prescription benefit program.
                                </p>
                                <p>One piece of information is the name of the health plan/PBM.</p>
                                <p>
                                    If the health plan uses the NCPDP Pharmacy ID and Combination Card Implementation
                                    Guide that NCPDP members have created, the BIN/IIN is clearly shown on the card.
                                </p>
                                <p>
                                    The pharmacy system then includes this BIN/IIN in the electronic transaction the
                                    pharmacy sends to the payer for the prescription.
                                </p>
                                <p>
                                    The BIN/IIN is a field in the Telecommunication Standard that is used for the
                                    routing and identification in pharmacy claims.
                                </p>
                                <p>
                                    The BIN/IIN field will either be filled with the ANSI IIN or the NCPDP Processor
                                    BIN, depending on which one the health plan has obtained.
                                </p>
                                <p>Leading zeroes in the NCPDP Processor BIN are significant.</p>
                                <p>
                                    In approximately 1989 the pharmacy services sector began using electronic processing
                                    for pharmacy claims.
                                </p>
                                <p>
                                    This electronic processing of pharmacy claims meant the health plan (or their
                                    Pharmacy Benefit Manager/processor/payer on their behalf) needed a number for the
                                    electronic routing of transactions.
                                </p>
                                <p>Some health plans decided to use magnetic stripe cards, some did not.</p>
                                <p>Those that use magnetic stripe cards apply to ANSI for an IIN.</p>
                                <p>These numbers commonly begin with &#34;6&#34; as in &#34;610014&#34;.</p>
                                <p>
                                    Those health plans that did not have a business reason for creating pharmacy benefit
                                    cards with a magnetic stripe were in need of a number, because they still needed to
                                    process electronic pharmacy claims.
                                </p>
                                <p>
                                    NCPDP provides a service to those health plans that do not use magnetic stripe
                                    cards. The NCPDP Processor BIN is a six-digit sequential number that begins with
                                    &#34;0&#34; as in &#34;000010&#34;. NCPDP does not reuse numbers.
                                </p>
                                <p className="caption">
                                    Source: Adapted from the National Council for Prescription Drug Programs (NCPDP){' '}
                                    <a
                                        href="https://www.ncpdp.org/Resources/NCPDP-Processor-ID-(BIN)"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        https://www.ncpdp.org/Resources/NCPDP-Processor-ID-(BIN)
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link btn-block text-left accordion-button collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        What is PCN?
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseTwo"
                                className="collapse"
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <p>
                                        The Processor Control Number (PCN) is a secondary identifier that may be used in
                                        routing of pharmacy transactions.
                                    </p>
                                    <p>
                                        A PBM/processor/plan may choose to differentiate different plans/benefit
                                        packages with the use of unique PCNs.
                                    </p>
                                    <p>
                                        The PCN is defined by the PBM/processor as this identifier is unique to their
                                        business needs.
                                    </p>
                                    <p>
                                        There is no registry of PCNs. The PCN is alphanumeric as defined by the
                                        PBM/processor.
                                    </p>
                                    <p>
                                        The PCN, like the BIN/IIN appears on the pharmacy ID card, in accordance with
                                        the rules defined in the NCPDP Pharmacy ID and Combination Card Implementation
                                        Guide.
                                    </p>
                                    <p>
                                        Not all entities use the PCN to differentiate plans. Some entities may use the
                                        Group ID; still others may not need this level of differentiation.
                                    </p>
                                    <p className="caption">
                                        Source: Adapted from the National Council for Prescription Drug Programs (NCPDP){' '}
                                        <a
                                            href="https://www.ncpdp.org/Resources/NCPDP-Processor-ID-(BIN)"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            https://www.ncpdp.org/Resources/NCPDP-Processor-ID-(BIN)
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link btn-block text-left accordion-button collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        What is a group?
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <p>
                                        The Group number is a tertiary identifier that may be used routing of pharmacy
                                        transactions.
                                    </p>
                                    <p>
                                        A PBM/processor/plan may choose to differentiate different plans/benefit
                                        packages with the use of unique Groups.
                                    </p>
                                    <p>
                                        The Group is defined by the PBM/processor as this identifier is unique to their
                                        business needs.
                                    </p>
                                    <p>
                                        There is no registry of Groups. The Group is alphanumeric as defined by the
                                        PBM/processor.
                                    </p>
                                    <p>
                                        The Group, like the BIN/IIN and PCN appears on the pharmacy ID card, in
                                        accordance with the rules defined in the NCPDP Pharmacy ID and Combination Card
                                        Implementation Guide.
                                    </p>
                                    <p>
                                        Not all entities use the Group to differentiate plans. Some entities may use the
                                        Group ID; still others may not need this level of differentiation.
                                    </p>
                                    <p className="caption">
                                        Source: Adapted from the National Council for Prescription Drug Programs (NCPDP){' '}
                                        <a
                                            href="https://www.ncpdp.org/Resources/NCPDP-Processor-ID-(BIN)"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            https://www.ncpdp.org/Resources/NCPDP-Processor-ID-(BIN)
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Faqs;
