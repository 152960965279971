import React, { Component } from 'react';
import './CustomSnackBar.styles.scss';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

class CustomSnackBar extends Component {
    render() {
        const { message, onClose, variant } = this.props;
        const styleClass = `CustomSnackBar-container CustomSnackBar-${variant}`;

        return (
            <Snackbar
                anchorOrigin={this.props.anchorOrigin}
                open={this.props.open}
                autoHideDuration={this.props.hideAfter}
                onClose={this.props.onClose}
            >
                <Alert
                    onClose={onClose}
                    severity={variant}
                    className={styleClass}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleIcon sx={{ color: 'white' }} />,
                        warning: <WarningIcon sx={{ color: 'white' }} />,
                        error: <ErrorIcon sx={{ color: 'white' }} />,
                        info: <InfoIcon sx={{ color: 'white' }} />,
                    }}
                >
                    <span className="CustomSnackBar-message">{message}</span>
                </Alert>
            </Snackbar>
        );
    }
}

CustomSnackBar.propTypes = {
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'n/a']).isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    anchorOrigin: PropTypes.object.isRequired,
    hideAfter: PropTypes.number.isRequired,
};

export default CustomSnackBar;
