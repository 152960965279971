import React from 'react';
import './Footer.styles.scss';

const Footer = () => (
    <div className='footer-wrapper'>
        <div className="Footer-container py-5 px-lg-5">
            <div className="Footer-row">
                <div className="Footer-row row justify-content-md-between">
                    <div className="col-lg-9">
                        <img className='logo-footer' src="/assets/pmg_logo_ko@2x.png" alt="Precision Medicine Group" />
                        <p className="mt-4">
                            <small>
                                Precision Medicine Group is an integrated team of experts in fields from advanced lab
                                sciences to translational informatics and regulatory affairs, payer insights to
                                marketing communications. Together, we help our pharmaceutical and life-sciences clients
                                conquer product development and commercialization challenges in a rapidly evolving
                                environment.
                            </small>
                        </p>
                    </div>

                    <div className="col-sm d-block d-md-none">
                        <hr />
                    </div>

                    <div className="Footer-text-2 col-lg-2 col-sm-12">
                        <p className="text-uppercase fw-bolder">
                            <a href='https://www.precisionmedicinegrp.com/services'>Solutions</a>
                        </p>
                        <p className="text-uppercase fw-bolder">
                            <a href='https://careers.precisiongroupcompanies.com/'>Careers</a>
                        </p>
                    </div>

                    <div className="col-sm-12">
                        <hr />
                    </div>
                    <div className="Footer-text-2">
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <p>
                                    <small className='d-block d-md-inline'>© 2023 Precision Medicine Group, LLC. All Rights Reserved.</small>{' '}
                                    <a className='small' href="https://www.precisionmedicinegrp.com/privacy-policy/">Privacy</a> |{' '}
                                    <a className='small' href="https://www.precisionmedicinegrp.com/gdpr/">GDPR</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
