import axios from 'axios';
import { log } from '../helper/helper';
import { v4 as uuidv4 } from 'uuid';

const Api = {
    // localStorage acting as state manager
    getApiHost: () => {
        const apiHost = localStorage.getItem('apiHost');
        return apiHost;
    },

    fetchConfig: () => {
        const config = {
            headers: {
                'ContenCache-Control': 'no-cache',
            },
        };
        const randomId = uuidv4();

        return axios.get('env.json?random=' + randomId, config);
    },

    fetchData: (userId, bin, pcn, group, recaptchaToken) => {
        const apiHost = Api.getApiHost();
        log(`fetchData. userId: ${userId} apiHost LS: ${localStorage.getItem('apiHost')}`);
        log(`fetchData. bin: ${bin} pcn: ${pcn}  group: ${group} recaptchaToken: ${recaptchaToken}`);
        return axios.get(`${apiHost}/payer-information`, {
            params: {
                userId,
                bin,
                pcn,
                group,
                recaptchaToken,
            },
        });
    },

    fetchBulkData: (userId, searchQueries) => {
        const apiHost = Api.getApiHost();
        return axios.post(`${apiHost}/payer-information`, {
            searchQueries,
            userId,
        });
    },

    saveUser: (user) => {
        const apiHost = Api.getApiHost();
        return axios.put(`${apiHost}/user`, {
            user,
        });
    },
};

export default Api;
