import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './EmailDialog.styles.scss';

class EmailDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            surname: '',
            validEmail: true,
        };
    }

    render() {
        return (
            <Dialog
                className="EmailDialog-container"
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="email-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.text}
                    <TextField
                        inputProps={{ size: 50, maxLength: 50 }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={this.state.name}
                        onChange={this.handleChange}
                        variant="standard"
                    />
                    <TextField
                        inputProps={{ size: 50, maxLength: 50 }}
                        margin="dense"
                        id="surname"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={this.state.surname}
                        onChange={this.handleChange}
                        variant="standard"
                    />
                    <TextField
                        inputProps={{ size: 50, maxLength: 50 }}
                        required
                        margin="dense"
                        id="email"
                        label="Email Address"
                        error={!this.state.validEmail}
                        type="email"
                        fullWidth
                        value={this.state.email}
                        onChange={this.handleChange}
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
        if (event.target.id === 'email') {
            this.setState({ validEmail: this.validEmail(event.target.value) });
        }
    };

    handleSubmit = () => {
        this.props.handleSubmit({ name: this.state.name, surname: this.state.surname, email: this.state.email });
    };

    validEmail = (email) => {
        if (typeof email !== 'undefined') {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf('@@') == -1 &&
                    lastDotPos > 2 &&
                    email.length - lastDotPos > 2
                )
            ) {
                return false;
            }
            return true;
        }
    };
}

EmailDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.object,
};

export default EmailDialog;
