// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../ui/bpg-hero.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppTopHeader-container{position:absolute;top:0;right:0;left:0;bottom:0;z-index:-1;height:100%;width:100%;opacity:1;font-size:1.125rem;font-family:\"Source Sans Pro\",sans-serif !important;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");overflow-x:hidden}.MuiGrid-item-2{max-width:100% !important}", "",{"version":3,"sources":["webpack://./src/components/App.styles.scss"],"names":[],"mappings":"AAGI,wBACI,iBAAA,CACA,KAAA,CACA,OAAA,CACA,MAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,SAAA,CACA,kBAAA,CACA,mDAAA,CACA,wDAAA,CACA,iBAAA,CAIR,gBACI,yBAAA","sourcesContent":["@import '../ui/scss/variables.scss';\n\n.App {\n    &TopHeader-container {\n        position: absolute;\n        top: 0;\n        right: 0;\n        left: 0;\n        bottom: 0;\n        z-index: -1;\n        height: 100%;\n        width: 100%;\n        opacity: 1;\n        font-size: 1.125rem;\n        font-family: 'Source Sans Pro', sans-serif !important;\n        background-image: url('../ui/bpg-hero.jpg');\n        overflow-x: hidden;\n    }\n}\n\n.MuiGrid-item-2 {\n    max-width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
