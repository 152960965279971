import React, { Component } from 'react';
import './SearchBar.styles.scss';
import PropTypes from 'prop-types';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Popover from '@mui/material/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const messages = {
    EMPTY: 'Please enter at least one field to search ...',
    NOT_NUMERIC: 'Only valid values may be entered. Please use numbers 0-9 only.',
    INVALID_SIZE: 'Invalid BIN length. Please enter a BIN length of either 6 or 8 digits.',
    VALID: '',
};

const validBinSizes = [6, 8];

function ErrorMessage(props) {
    const open = Boolean(props.anchorEl);
    const id = open ? 'error-popover' : undefined;
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            classes={{
                paper: 'Search-PopOver-container',
            }}
        >
            {props.msg}
        </Popover>
    );
}

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.binAnchorRef = React.createRef();
        this.binRef = React.createRef();
        this.pcnRef = React.createRef();
        this.groupRef = React.createRef();
        this.state = {
            bin: '',
            pcn: '',
            group: '',
            errors: {
                bin: messages.EMPTY,
            },
            anchorEl: null,
            allowSearch: false,
            recaptchaToken: null,
        };
    }

    handleChangeCaptcha = (recaptchaToken) => {
        this.setState({
            allowSearch: recaptchaToken && this.state.errors.bin === messages.VALID,
            recaptchaToken,
        });
    };

    handleSearch = async () => {
        const {
            errors: { bin },
        } = this.state;

        const isValid = await this.validateCaptcha();
        console.log('isValid: ', isValid);
        if (!isValid) {
            return;
        }
        if (bin === messages.VALID) {
            return this.props.handleSearch(this.state.bin, this.state.pcn, this.state.group, this.state.recaptchaToken);
        }
        this.showPopOver(this.binAnchorRef.current);
    };

    handleChange = ({ target: { id, value } }) => {
        const errorMesage = this.getErrorMessage(id === 'bin' ? value : this.state.bin);
        this.setState({
            [id]: value.toUpperCase(),
            allowSearch: errorMesage === messages.VALID,
            // allowSearch: this.validateCaptcha() && errorMesage === messages.VALID,
            errors: {
                ...this.state.errors,
                bin: errorMesage,
            },
        });
        this.validateForm(errorMesage);
    };

    validateForm = (errorMesage) => {
        if (errorMesage === messages.NOT_NUMERIC) {
            this.showPopOver(this.binAnchorRef.current);
        }
    };

    validateCaptcha = async () => {
        if (this.props.recaptchaEnabled === 'false') {
            return true;
        }

        const token = await this.handleVerifyRecaptcha();

        if (this.props.recaptchaSiteKey && (this.state.recaptchaToken || token)) {
            return true;
        }
        return false;
    };

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;
    
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
    
        let token;
        try {
            token = await executeRecaptcha('homepage');
        }
        catch (error) {
            console.log('error: ', error);
        }

        return token;
    };

    getErrorMessage = (bin) => {
        let errorMessage = bin ? messages.VALID : messages.EMPTY;
        if (!validBinSizes.includes(bin.length)) {
            errorMessage = messages.INVALID_SIZE;
        }
        if (isNaN(bin)) {
            errorMessage = messages.NOT_NUMERIC;
        }
        return errorMessage;
    };

    showPopOver = (targetInput) => {
        this.setState({ anchorEl: targetInput });
    };

    handleClosePopOver = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        return (
            <>
                <div className="input-group-outline input-group">
                    <input
                        id="bin"
                        placeholder="RX BIN"
                        type="text"
                        value={this.state.bin}
                        onChange={this.handleChange}
                        ref={this.binRef}
                        aria-label="RX BIN"
                        aria-describedby="button-addon2"
                        className="form-control"
                    />
                    <div id="errorMessageAnchorForBin" ref={this.binAnchorRef} />
                    {this.state.errors.bin ? (
                        <ErrorMessage
                            anchorEl={this.state.anchorEl}
                            handleClose={this.handleClosePopOver}
                            msg={this.state.errors.bin}
                        />
                    ) : null}
                    <input
                        type="text"
                        id="pcn"
                        value={this.state.pcn}
                        onChange={this.handleChange}
                        ref={this.pcnRef}
                        className="form-control"
                        placeholder="RX PCN"
                        aria-label="RX PCN"
                        aria-describedby="button-addon2"
                    />
                    <input
                        type="text"
                        id="group"
                        value={this.state.group}
                        onChange={this.handleChange}
                        ref={this.groupRef}
                        className="form-control"
                        placeholder="RX GROUP"
                        aria-label="RX GROUP"
                        aria-describedby="button-addon2"
                    />
                    <button
                        type="button"
                        onClick={async () => await this.handleSearch()}
                        className={this.state.allowSearch ? 'btn btn-lookup' : 'btn btn-lookup-disabled'}
                    >
                        <FontAwesomeIcon icon={faSearch} className="SearchBar-icon" />
                        Search
                    </button>
                    {this.props.recaptchaEnabled == 'true' ? (
                        <div className="SearchBar-captcha-container">
                            {/* <ReCAPTCHA
                                size="normal"
                                sitekey={this.props.recaptchaSiteKey}
                                onChange={this.handleChangeCaptcha}
                            /> */}
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

ErrorMessage.propTypes = {
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
};

SearchBar.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    recaptchaSiteKey: PropTypes.string,
    recaptchaEnabled: PropTypes.string,
    googleReCaptchaProps: PropTypes.object,
};

const WithSearchBar = withGoogleReCaptcha(SearchBar);

export default WithSearchBar;
