import React from 'react';
import './Header.styles.scss';

const Header = () => (
    <div className="container-sm mt-4 pos-relative">
        <div className="row justify-content-sm-center">
            <div className="col-sm col-lg-10 text-center">
                <h1 className="title">UNMASK and CONNECT</h1>
                <h2 className="sub-title mb-3">hidden payer information across data vendors</h2>
                <p className="header-text">
                    Leverage PRECISIONxtract’s dedicated team of payer data experts, our curated relationships to Payers
                    and PBMs, and the BPG Lookup product to map the BIN PCN GROUP identifiers in your dataset to a
                    Health Plan. When you can bring together cross-data asset insights across all syndicated data
                    vendors, you can build sophisticated analytics and reporting capabilities individualized to the
                    payer type.
                </p>
                <p className="instruction">Enter as much information as you have</p>
            </div>
        </div>
    </div>
);

export default Header;
