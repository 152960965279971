import React from 'react';
import './DetailedChannel.styles.scss';

const DetailedChannel = () => (
    <div className="DetailedChannel-container mt-2 mb-3">
        <div className="row justify-content-sm-center">
            <div className="col-sm col-lg-3"></div>
            <div className="col-sm col-lg-6">
                <h2 className="DetailedChannel-title">With BPG Lookup, get access to detailed channel descriptors</h2>
                <p className="DetailedChannel-subtitle">
                    Consider what you could do differently with precise information on Commercial Health Plans,
                    Employers, Unions, Workers compensation, ADAP, Medicare, EGWP, Managed Medicaid, State Medicaid,
                    Voucher Programs, and others.
                </p>
            </div>
            <div className="col-sm col-lg-3"></div>
        </div>
    </div>
);

export default DetailedChannel;
