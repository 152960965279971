import React, { Component } from 'react';
import './App.styles.scss';
import Grid from '@mui/material/Grid';
import TopHeader from './TopHeader/TopHeader';
import Header from './Header/Header';
import Search from './Search/Search';
import Results from './Results/Results';
import Connect from './Connect/Connect';
import Faqs from './Faqs/Faqs';
import Footer from './Footer/Footer';
import Contact from './Contact/Contact';
import { v4 as uuidv4 } from 'uuid';
import CustomSnackBar from './CustomSnackbar/CustomSnackBar';
import EmailModal from './EmailDialog/EmailDialog';
import Api from '../services/Api';
import DialogContentText from '@mui/material/DialogContentText';
import { log } from '../helper/helper';
import ReactGA from 'react-ga';
import DetailedChannel from './DetailedChannnel/DetailedChannel';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            user: null,
            searchResults: [],
            showResults: false,
            snackOpen: false,
            snackVariant: 'n/a',
            snackMsg: '',
            snackTime: 5000,
            leftSnackOpen: false,
            leftSnackVariant: 'n/a',
            leftSnackMsg: '',
            leftSnackTime: 5000,
            someNotAvail: false,
            emailModal: false,
            emailDialogText: null,
            emailDialogTitle: '',
            recaptchaSiteKey: '6LdtkQYkAAAAACtpPHqvuxXFN3-trxKh1yTesn8v', //null,
            recaptchaEnabled: null,
        };
    }

    componentDidMount() {
        const userId = this.getUser();

        Api.fetchConfig().then((results) => {
            const env = results.data;

            // localStorage acting as state manager
            localStorage.setItem('ENV', env.ENV);
            localStorage.setItem('host', env.HOST);
            localStorage.setItem('apiHost', env.API_URL);
            localStorage.setItem('captchaSiteKey', env.CAPTCHA_SITE_KEY);
            localStorage.setItem('captchaEnabled', env.CAPTCHA_ENABLED);
            localStorage.setItem('gaSiteKey', env.GA_SITE_KEY);

            log('env.ENV: ', env.ENV);
            log('env.HOST: ', env.HOST);
            log('env.API_URL: ', env.API_URL);
            log('env.CAPTCHA_SITE_KEY: ', env.CAPTCHA_SITE_KEY);
            log('env.CAPTCHA_ENABLED: ', env.CAPTCHA_ENABLED);
            log('env.GA_SITE_KEY: ', env.GA_SITE_KEY);

            // init Google Analytics
            this.initializeReactGA(env.GA_SITE_KEY);
            this.warmUpLambda();

            this.setState({
                userId,
                recaptchaSiteKey: env.CAPTCHA_SITE_KEY,
                recaptchaEnabled: env.CAPTCHA_ENABLED,
            });
        });
    }

    initializeReactGA = (siteKey) => {
        ReactGA.initialize(siteKey);
        ReactGA.pageview('/');
    };

    warmUpLambda = () => {
        Api.fetchData('', '', '', '', '')
            .then()
            .catch(() => {})
            .finally(() => {
                log('Lambda warmed up');
            });
    };

    getUser() {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            const newUserId = uuidv4();

            log(localStorage.getItem('ENV'), 'creating a new userId', newUserId);

            localStorage.setItem('userId', newUserId);
            return newUserId;
        } else {
            log(localStorage.getItem('ENV'), 'already have a userId from localStorage: ', userId);
            return userId;
        }
    }

    render() {
        return (
            <GoogleReCaptchaProvider
                useRecaptchaNet
                reCaptchaKey={this.state.recaptchaSiteKey}
                scriptProps={{ async: true, defer: true, appendTo: 'body' }}
            >
                <div className="App-container">
                    <a name="home" />
                    <Results
                        open={this.state.showResults}
                        handleClose={this.cleanResults}
                        searchResults={this.state.searchResults}
                    />
                    <Grid container spacing={0}>
                        <div className="wrapper-bla">
                            <div className="bla2">
                                <TopHeader></TopHeader>
                                <Header
                                    handleClickPricing={this.handleClickPricing}
                                    handleClickFaqs={this.handleClickFaqs}
                                />
                                <Search
                                    handleResults={this.updateResults}
                                    userId={this.state.userId}
                                    showSnack={this.showSnack}
                                    showLeftSnack={this.showLeftSnack}
                                    cleanResults={this.cleanResults}
                                    recaptchaSiteKey={this.state.recaptchaSiteKey}
                                    recaptchaEnabled={this.state.recaptchaEnabled}
                                />
                                <svg preserveAspectRatio="none" viewBox="0 0 1366 183" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 183h1366v-183c-312.3 154.3-946.3 256.1-1366 112.3z" fill="#fff" />
                                </svg>
                            </div>
                        </div>
                        <DetailedChannel />
                        <Connect />
                        <Faqs />
                        <Contact />
                        <Footer />
                    </Grid>
                    <CustomSnackBar
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        hideAfter={6000}
                        key={'top, left'}
                        open={this.state.leftSnackOpen}
                        onClose={this.handleCloseLeftSnack}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        variant={this.state.leftSnackVariant}
                        message={this.state.leftSnackMsg}
                    />
                    <CustomSnackBar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        hideAfter={3000}
                        key={'top, right'}
                        open={this.state.snackOpen}
                        onClose={this.handleCloseSnack}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        variant={this.state.snackVariant}
                        message={this.state.snackMsg}
                    />
                    <EmailModal
                        open={this.state.emailModal}
                        handleClose={this.handleCloseModal}
                        handleSubmit={this.handleSubmitModal}
                        text={this.state.emailDialogText}
                        title={this.state.emailDialogTitle}
                    />
                </div>
            </GoogleReCaptchaProvider>
        );
    }

    handleCloseModal = () => {
        this.setState({ emailModal: false });
    };

    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    handleCloseLeftSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ leftSnackOpen: false });
    };

    handleSubmitModal = (data) => {
        this.setState({ emailModal: false });
        const updatedUser = {
            ...this.state.user,
            name: data.name,
            surname: data.surname,
            email: data.email,
            userId: this.state.userId,
        };
        this.setState({ user: updatedUser });
        Api.saveUser(updatedUser)
            .then((results) => {
                this.showSnack('success', `Your details have been sent to us ! Thanks ${results.data.user.name} !`);
                this.setState({ user: updatedUser });
            })
            .catch((e) => console.error('e: ', e));
    };

    cleanResults = () => {
        this.setState({
            showResults: false,
            searchResults: [],
        });
    };

    updateResults = (searchResponse) => {
        let searchRowResults = [];

        log(localStorage.getItem('ENV'), 'searchResponse: ', searchResponse);

        let emailDialogTitle = '';
        let emailDialogText = '';

        if (searchResponse.data.maxReached) {
            this.showSnack('warning', 'You reached the maximum requests allowed, please contact us !');

            emailDialogTitle = 'Search Limit Reached!';
            emailDialogText = (
                <React.Fragment>
                    <DialogContentText>
                        We&lsquo;re sorry, you have reached the search limit of 30 searches.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        Here&lsquo;s how to contact us if you are interested in our BPG Lookup services
                    </DialogContentText>
                    <br />
                </React.Fragment>
            );

            this.setState({
                showQuery: false,
                showResults: true,
                user: searchResponse.user,
                emailModal: true,
                emailDialogText,
                emailDialogTitle,
            });
        } else {
            let someNotAvail = false;

            searchResponse.data.queryResults.forEach((queryResult) => {
                if (queryResult.results.length === 0) {
                    someNotAvail = true;
                } else {
                    searchRowResults = searchRowResults.concat(queryResult.results);
                }
            });

            const user = searchResponse.data.user;
            const requestsLeft = searchResponse.data.requestsLeft;

            this.setState({
                searchResults: searchResponse.data.queryResults,
                showResults: !someNotAvail,
                showQuery: true,
                user,
            });

            if (someNotAvail) {
                emailDialogTitle = 'Match Not Found!';
                emailDialogText = (
                    <React.Fragment>
                        <DialogContentText>
                            We&lsquo;re sorry, we could not find a match for some of the values you submitted.
                        </DialogContentText>
                        <br />
                        <DialogContentText>We will continue trying to match the values.</DialogContentText>
                        <br />
                        <DialogContentText>
                            Please leave your name and email address if you would like to be contacted when the result
                            is available.
                        </DialogContentText>
                        <br />
                    </React.Fragment>
                );

                this.setState({
                    someNotAvail,
                    emailModal: true,
                    emailDialogText,
                    emailDialogTitle,
                });
            }

            if (searchRowResults.length > 0) {
                if (someNotAvail) {
                    this.showSnack(
                        'info',
                        `Search has been done. But some results are missing. You have ${requestsLeft} requests left`
                    );
                } else {
                    this.showSnack('success', `Search has been successfull ! You have ${requestsLeft} requests left`);
                }
            } else {
                this.showSnack('info', `Search had no results ... You have ${requestsLeft} requests left`);
            }
        }
    };

    showSnack = (variant, msg) => {
        this.setState({
            snackOpen: true,
            snackVariant: variant,
            snackMsg: msg,
        });
    };

    showLeftSnack = (variant, msg) => {
        this.setState({
            leftSnackOpen: true,
            leftSnackVariant: variant,
            leftSnackMsg: msg,
        });
    };
}

App.propTypes = {};

export default App;
