import React, { Component } from 'react';
import './ResultsAsCards.styles.scss';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

class ResultsAsCards extends Component {
    renderTooltip(title, ariaDesc) {
        return (
            <Tooltip title={title}>
                <IconButton aria-label={ariaDesc} className="ResultsAsCards-tooltip-icon-btn" size="large">
                    <FontAwesomeIcon icon={faQuestionCircle} className="ResultsAsCards-tooltip-icon"></FontAwesomeIcon>
                </IconButton>
            </Tooltip>
        );
    }

    render() {
        const cards = this.props.searchResults.map((queryResult, idx) => {
            const hasRows = queryResult.results.length > 0;
            const row = queryResult.results[0];
            const { bin, pcn, group } = queryResult.query;
            const queryKey = `${bin ? bin : 'NULL'}~${pcn ? pcn : 'NULL'}~${group ? group : 'NULL'}`;

            return (
                <Card key={idx} className="ResultsAsCards-container">
                    <CardContent className="ResultsAsCards-content">
                        <div className="ResultsAsCards-row">
                            <div className="ResultsAsCards-header-cell">
                                <div>Entry</div>
                                {this.renderTooltip('The Rx BIN-PCN-Group entered', 'Entry column description')}
                            </div>
                            <div className="ResultsAsCards-content-cell">{queryKey}</div>
                        </div>

                        <div className="ResultsAsCards-row">
                            <div className="ResultsAsCards-header-cell">
                                <div>Plan</div>
                                {this.renderTooltip(
                                    'Name of each health plan or affiliate',
                                    'Pharmacy Payer Entity column description'
                                )}
                            </div>
                            <div className="ResultsAsCards-content-cell">
                                {hasRows && row.Pharmacy_PE_Plan ? row.Pharmacy_PE_Plan : 'N/A'}
                            </div>
                        </div>
                        <div className="ResultsAsCards-row">
                            <div className="ResultsAsCards-header-cell">
                                <div>Payer</div>
                                {this.renderTooltip(
                                    'Name of each parent organization',
                                    'Pharmacy Enterprise column description'
                                )}
                            </div>
                            <div className="ResultsAsCards-content-cell">
                                {hasRows && row.Enterprise ? row.Enterprise : 'N/A'}
                            </div>
                        </div>
                        <div className="ResultsAsCards-row">
                            <div className="ResultsAsCards-header-cell">
                                <div>Channel</div>
                                {this.renderTooltip(
                                    `Name of the benefit population 
                                        (e.g. Commercial, Medicare, FFS Medicaid, Managed Medicaid)`,
                                    'Population Description column description'
                                )}
                            </div>
                            <div className="ResultsAsCards-content-cell">
                                {hasRows && row.POP_Desc ? row.POP_Desc : 'N/A'}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            );
        });

        return cards;
    }
}

ResultsAsCards.propTypes = {
    searchResults: PropTypes.arrayOf(
        PropTypes.shape({
            results: PropTypes.arrayOf(
                PropTypes.shape({
                    Pharmacy_PE_Plan: PropTypes.string.isRequired,
                    Enterprise: PropTypes.string.isRequired,
                    POP_Desc: PropTypes.string.isRequired,
                })
            ).isRequired,
            query: PropTypes.shape({
                bin: PropTypes.string,
                pcn: PropTypes.string,
                group: PropTypes.string,
            }).isRequired,
        })
    ).isRequired,
};

export default ResultsAsCards;
