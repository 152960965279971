import React, { Component } from 'react';
import './Results.styles.scss';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import ResultsAsTable from '../ResultsAsTable/ResultsAsTable';
import ResultsAsCards from '../ResultsAsCards/ResultsAsCards';
import MediaQuery from 'react-responsive';

class Results extends Component {
    render() {
        return (
            <Grid container>
                <Modal
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    className="Modal-container"
                >
                    <>
                        <IconButton className="Results-close-button" onClick={this.props.handleClose} size="large">
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="Results-close-button-icon"
                            ></FontAwesomeIcon>
                        </IconButton>
                        <Grid className="Results-container">
                            <MediaQuery minDeviceWidth={1} maxWidth={649}>
                                <ResultsAsCards searchResults={this.props.searchResults} />
                            </MediaQuery>
                            <MediaQuery minDeviceWidth={650}>
                                <ResultsAsTable searchResults={this.props.searchResults} />
                            </MediaQuery>
                        </Grid>
                    </>
                </Modal>
            </Grid>
        );
    }
}

Results.propTypes = {
    searchResults: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default Results;
