import React, { Component } from 'react';
import './Search.styles.scss';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import SearchBar from '../SearchBar/SearchBar';
import Api from '../../services/Api';
import Loading from '../Loading/Loading';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bin: '',
            pcn: '',
            group: '',
            queriesFromFile: [],
            searching: false,
        };
    }

    showPopOver = (targetInput) => {
        this.setState({ anchorEl: targetInput });
    };

    handleClosePopOver = () => {
        this.setState({ anchorEl: null });
    };

    singleSearch = (bin, pcn, group, recaptchaToken) => {
        this.setState({ searching: true });
        Api.fetchData(this.props.userId, bin, pcn, group, recaptchaToken)
            .then(this.props.handleResults)
            .catch((e) => console.error('Error: ', e))
            .finally(() => {
                this.setState({ searching: false });
            });
    };

    bulkSearch = (queries) => {
        this.setState({ searching: true });
        Api.fetchBulkData(this.props.userId, queries)
            .then(this.props.handleResults)
            .catch((e) => console.error('Error: ', e))
            .finally(() => {
                this.setState({ searching: false });
            });
    };

    handleSingleSearch = (bin, pcn, group, recaptchaToken) => {
        return this.singleSearch(bin, pcn, group, recaptchaToken);
    };

    handleBulkSearch = (queriesFromFile) => {
        return this.bulkSearch(queriesFromFile);
    };

    handleFileUploaded = (newQueries) => {
        /* Update state */
        this.setState({
            queriesFromFile: [...this.state.queriesFromFile, ...newQueries],
        });

        this.handleBulkSearch(this.state.queriesFromFile);
    };

    render() {
        return (
            <React.Fragment>
                <Grid container className="Search-container">
                    <a name="search" className="Search-anchor" />
                    <Grid item xs={12} md={8} lg={6} className="Search-input-container">
                        <SearchBar
                            bin={this.state.bin}
                            pcn={this.state.pcn}
                            group={this.state.group}
                            handleSearch={this.handleSingleSearch}
                            recaptchaSiteKey={this.props.recaptchaSiteKey}
                            recaptchaEnabled={this.props.recaptchaEnabled}
                        />
                    </Grid>
                </Grid>
                <Loading isLoading={this.state.searching} />
            </React.Fragment>
        );
    }
}

Search.propTypes = {
    userId: PropTypes.string.isRequired,
    handleResults: PropTypes.func.isRequired,
    showSnack: PropTypes.func.isRequired,
    showLeftSnack: PropTypes.func.isRequired,
    cleanResults: PropTypes.func.isRequired,
    recaptchaSiteKey: PropTypes.string,
    recaptchaEnabled: PropTypes.string,
};

export default Search;
