import React, { Component } from 'react';
import './ResultsAsTable.styles.scss';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

class ResultsAsTable extends Component {
    render() {
        return (
            <Table className="ResultsAsTable-table" padding="none">
                <TableHead className="ResultsAsTable-table-row-head">
                    <TableRow>
                        <TableCell className="ResultsAsTable-table-header-cell" size="medium">
                            <span>Entry</span>
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        <span className="ResultsAsTable-tooltip-content">
                                            The Rx BIN-PCN-Group entered
                                        </span>
                                    </React.Fragment>
                                }
                            >
                                <IconButton
                                    aria-label="Key column description"
                                    className="ResultsAsTable-tooltip-icon-btn"
                                    size="large"
                                >
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className="ResultsAsTable-tooltip-icon"
                                    ></FontAwesomeIcon>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell className="ResultsAsTable-table-header-cell" size="small" align="left">
                            <span>Plan</span>
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        <span className="ResultsAsTable-tooltip-content">
                                            Name of each health plan or affiliate
                                        </span>
                                    </React.Fragment>
                                }
                            >
                                <IconButton
                                    aria-label="Key column description"
                                    className="ResultsAsTable-tooltip-icon-btn"
                                    size="large"
                                >
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className="ResultsAsTable-tooltip-icon"
                                    ></FontAwesomeIcon>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell className="ResultsAsTable-table-header-cell" size="small" align="left">
                            <span>Payer</span>
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        <span className="ResultsAsTable-tooltip-content">
                                            Name of each parent organization
                                        </span>
                                    </React.Fragment>
                                }
                            >
                                <IconButton
                                    aria-label="Key column description"
                                    className="ResultsAsTable-tooltip-icon-btn"
                                    size="large"
                                >
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className="ResultsAsTable-tooltip-icon"
                                    ></FontAwesomeIcon>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell className="ResultsAsTable-table-header-cell" size="small" align="left">
                            <span>Channel</span>
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        <span className="ResultsAsTable-tooltip-content">
                                            Name of the benefit population (e.g. Commercial, Medicare, FFS Medicaid,
                                            Managed Medicaid)
                                        </span>
                                    </React.Fragment>
                                }
                            >
                                <IconButton
                                    aria-label="Key column description"
                                    className="ResultsAsTable-tooltip-icon-btn"
                                    size="large"
                                >
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className="ResultsAsTable-tooltip-icon"
                                    ></FontAwesomeIcon>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.searchResults.map((queryResult, idx) => {
                        const hasRows = queryResult.results.length > 0;
                        const row = queryResult.results[0];
                        const { bin, pcn, group } = queryResult.query;
                        const queryKey = `${bin ? bin : 'NULL'}~${pcn ? pcn : 'NULL'}~${group ? group : 'NULL'}`;

                        return (
                            <TableRow key={idx} className="ResultsAsTable-table-row-content">
                                <TableCell
                                    className="ResultsAsTable-table-row-cell"
                                    size="medium"
                                    component="th"
                                    scope="row"
                                >
                                    {queryKey}
                                </TableCell>
                                <TableCell className="ResultsAsTable-table-row-cell" size="small" align="left">
                                    {hasRows ? row.Pharmacy_PE_Plan : 'N/A'}
                                </TableCell>
                                <TableCell className="ResultsAsTable-table-row-cell" size="small" align="left">
                                    {hasRows ? row.Enterprise : 'N/A'}
                                </TableCell>
                                <TableCell className="ResultsAsTable-table-row-cell" size="small" align="left">
                                    {hasRows ? row.POP_Desc : 'N/A'}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

ResultsAsTable.propTypes = {
    searchResults: PropTypes.arrayOf(
        PropTypes.shape({
            results: PropTypes.arrayOf(
                PropTypes.shape({
                    Pharmacy_PE_Plan: PropTypes.string.isRequired,
                    Enterprise: PropTypes.string.isRequired,
                    POP_Desc: PropTypes.string.isRequired,
                })
            ).isRequired,
            query: PropTypes.shape({
                bin: PropTypes.string,
                pcn: PropTypes.string,
                group: PropTypes.string,
            }).isRequired,
        })
    ).isRequired,
};

export default ResultsAsTable;
