import React from 'react';
import './Menu.styles.scss';
import './TopHeader.styles.scss';

const TopHeader = () => (
    <div className="container-fluid g-0 py-4 pos-relative">
        <div className="row">
            <div className="col-sm d-flex align-items-center">
                <div className="logo">
                    <object data="assets/lookup.svg" type="image/svg+xml" />
                </div>
            </div>
            <div className="col-sm text-end me-3 d-none d-md-block">
                <p className="powered-by">
                    <strong>Powered By:</strong>
                </p>
                <a className='powered-by-link' href="https://www.precisionxtract.com/cdms/data/payer-master/">
                    <img src="assets/xtract.svg" alt="Precision Xtract" />
                </a>
            </div>
        </div>
    </div>
);

export default TopHeader;
