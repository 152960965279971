import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bars } from 'react-loader-spinner';
import './Loading.styles.scss';
class Loading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return this.props.isLoading ? (
            <Bars
                wrapperClass="Loading-wrapper"
                color="#24495c"
                visible={this.props.isLoading}
                height={100}
                width={100}
            />
        ) : null;
    }
}

Loading.propTypes = {
    isLoading: PropTypes.bool,
};

export default Loading;
