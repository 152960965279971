import React from 'react';
import './Connect.styles.scss';

const Connect = () => (
    <div className="container-sm info-cards">
        <div className="row card-row justify-content-sm-center align-items-stretch">
            <div className="col-sm-12 col-md-5">
                <div className="card border-0 w-100 my-2">
                    <div className="card-body">
                        <h4 className="card-title mb-3">Who we help</h4>
                        <ul className="help-list">
                            <li>Pharmaceutical Manufacturers</li>
                            <li>Retail & Specialty Pharmacies</li>
                            <li>Payers</li>
                            <li>Syndicated Data Vendors & Data Aggregators</li>
                            <li>PBMs</li>
                            <li>EMR Vendors</li>
                            <li>Health Systems</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-5 d-flex align-items-stretch">
                <div className="card border-0 w-100 my-2">
                    <div className="card-body">
                        <h4 className="card-title">How we help</h4>
                        <ul className="help-list">
                            <li>Subscription to Precision Xtract’s Periodic Reference Databases</li>
                            <li>
                                Measure the impact of data capture issues and control downstream delays in reimbursement
                            </li>
                            <li>Unlock your data</li>
                            <li>Get actionable insights</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Connect;
